import {
  getDimensionFromTitle,
  getDimensionTranslationFromTitle
} from './explore'
import { formatDate, yearsToYearsMonthsDays } from './date'
import { addSpaceInLargeNumber, formatLargeNumber } from './number'
import type { ResultSetItemValue } from '~/types/query'
import { DATA_EXPORT_TYPE } from '~/types/dataExport'
import type { Filter, ScopeFilter } from '~/types/analytics'
import {
  CustomizationFieldType,
  type CustomizationFieldWithOptions
} from '~/types/customization'
import { FormatContext, MemberFormat } from '~/types/analyticsFormat'
import { EMPTY_VALUE } from '~/constants/cube'

export const formatMemberValue = (
  value: ResultSetItemValue,
  format: MemberFormat,
  formatContext: FormatContext,
  withHtml: boolean = true,
  exportType: DATA_EXPORT_TYPE = DATA_EXPORT_TYPE.CSV,
  isInSelectedPeriod: boolean = true
): string => {
  switch (formatContext) {
    case FormatContext.EXPORT:
      return formatValueForExport(value, format, exportType)

    case FormatContext.EVOLUTION: {
      value = isFinite(value as number) ? `${Math.abs(value as number)}` : '0'
      break
    }

    case FormatContext.KPI: {
      if (value === undefined || value === null) {
        if (!isInSelectedPeriod) {
          return '-'
        }

        value = '0'
      }
      break
    }

    case FormatContext.STAT: {
      if (value === undefined || value === null) {
        return 'N/A'
      }
      break
    }

    case FormatContext.CHART:
    case FormatContext.DEFAULT: {
      if (value === undefined || value === null) {
        return '-'
      }
      break
    }
  }

  switch (format) {
    case MemberFormat.NAME:
      return formatName(value as string)

    case MemberFormat.TRANSLATION_KEYS:
      return formatTranslationKeys(value as string)

    case MemberFormat.DIMENSION_TITLES:
      return formatDimensionTitles(value as string)

    case MemberFormat.DATE:
      // TODO: Change date format according to locale for english speaking users
      return formatDate(value as string)

    case MemberFormat.AGE_YEARS_MONTHS_DAYS:
      return formatAgeYearsMonthsDays(parseFloat(value as string), withHtml)

    case MemberFormat.AGE_YEARS:
      return formatAgeYears(value as string, withHtml)

    case MemberFormat.DAYS:
      return formatDays(parseFloat(value as string), withHtml)

    case MemberFormat.CURRENCY:
      return formatCurrency(value as string, withHtml)

    case MemberFormat.CURRENCY_EXACT_0:
      return formatCurrencyExact(value as string, 0, withHtml)

    case MemberFormat.CURRENCY_EXACT:
      return formatCurrencyExact(value as string, 2, withHtml)

    case MemberFormat.PERCENT:
      return formatPercent(value as string, 1, withHtml)

    case MemberFormat.PERCENT_0:
      return formatPercent(value as string, 0, withHtml)

    case MemberFormat.NUMBER_0:
      return formatNumber(parseFloat(value as string), 0)

    case MemberFormat.NUMBER_1:
      return formatNumber(parseFloat(value as string), 1)

    case MemberFormat.NUMBER_2:
      return formatNumber(parseFloat(value as string), 2)

    case MemberFormat.BOOLEAN:
      return formatBoolean(value as string)

    default:
      return value.toString()
  }
}

const formatValueForExport = (
  value: ResultSetItemValue,
  format: MemberFormat,
  exportType: DATA_EXPORT_TYPE
) => {
  if (value === undefined || value === null) {
    return exportType === DATA_EXPORT_TYPE.CSV ? '-' : ''
  }
  let formattedValue
  switch (format) {
    // We need to display a full and non-formatted value for numeric values
    case MemberFormat.CURRENCY:
    case MemberFormat.CURRENCY_EXACT:
    case MemberFormat.NUMBER_0:
    case MemberFormat.NUMBER_1:
    case MemberFormat.NUMBER_2:
      formattedValue = `${value}` // TODO: Handle correct currency
      break
    case MemberFormat.PERCENT:
      formattedValue = `${value}%`
      break
    default:
      formattedValue = formatMemberValue(
        value,
        format,
        FormatContext.DEFAULT,
        false
      )
  }
  // We add double quote around the string value so that ',' inside th value are not interpreted as a cell break
  return exportType === DATA_EXPORT_TYPE.CSV
    ? `"${formattedValue}"`
    : formattedValue
}

const formatName = (value: string) => {
  return value.split('//')[0]!
}

const formatNumber = (value: number, fractionDigits: number) => {
  return value.toFixed(fractionDigits)
}

const formatBoolean = (value: string | boolean) => {
  const { $t } = useNuxtApp()

  if ((value as boolean) === true || (value as string) === 'true') {
    return $t('cube.enum.boolean.true')
  } else {
    return $t('cube.enum.boolean.false')
  }
}

const formatAgeYearsMonthsDays = (value: number, withHtml: boolean) => {
  const { $t } = useNuxtApp()

  const yearsMonthsDays = yearsToYearsMonthsDays(parseFloat(`${value / 365}`))
  let finalText = ''

  if (yearsMonthsDays.years > 0) {
    finalText += `${yearsMonthsDays.years}${
      withHtml ? '<span class="small">' : ''
    } ${$t('date.text.years', { count: yearsMonthsDays.years })}${
      withHtml ? '</span>' : ''
    }`
  }

  if (yearsMonthsDays.months > 0) {
    if (finalText !== '') {
      finalText += `${withHtml ? '<span class="small">, </span>' : ', '}`
    }

    finalText += `${yearsMonthsDays.months}${
      withHtml ? '<span class="small">' : ''
    } ${$t(yearsMonthsDays.years ? 'date.text.M' : 'date.text.months', {
      count: yearsMonthsDays.months
    })}${withHtml ? '</span>' : ''}`
  }

  if (
    !yearsMonthsDays.years &&
    (!yearsMonthsDays.months || yearsMonthsDays.days > 1)
  ) {
    if (yearsMonthsDays.months) {
      yearsMonthsDays.days = Math.floor(yearsMonthsDays.days)
    }

    if (finalText !== '') {
      finalText += `${withHtml ? '<span class="small">, </span>' : ', '}`
    }

    finalText += `${yearsMonthsDays.days}${
      withHtml ? '<span class="small">' : ''
    } ${$t(
      yearsMonthsDays.years || yearsMonthsDays.months
        ? 'date.text.D'
        : 'date.text.days',
      { count: Math.floor(yearsMonthsDays.days) }
    )}${withHtml ? '</span>' : ''}`
  }
  return finalText
}

const formatDays = (value: number, withHtml: boolean) => {
  const { $t } = useNuxtApp()
  const number = formatNumber(value, 1)

  return `${number} ${withHtml ? '<span class="small">' : ''}${$t(
    'date.text.days',
    { count: parseInt(number) }
  )}`
}

export const formatFilterValues = (
  filter: ScopeFilter | Filter,
  shouldShrinkValues = false
) => {
  const dimension = getDimensionFromTitle(filter.member)

  const formattedValues = filter.values.map(value =>
    formatMemberValue(
      value,
      dimension.meta.format,
      FormatContext.DEFAULT,
      false
    )
  )

  if (formattedValues.length === 1) {
    return formattedValues[0] || EMPTY_VALUE
  }

  if (!shouldShrinkValues) {
    const displayedValues = formattedValues.map(value => {
      return value === null ? EMPTY_VALUE : value
    })
    return `${displayedValues.join(', ')}`
  } else {
    const { $t } = useNuxtApp()

    return `${formattedValues[0] || EMPTY_VALUE} ${$t(
      'dashboard.filters.dimension.others',
      { count: formattedValues.length - 1 }
    )}`
  }
}

export const formatCustomizationOptionLabel = (
  customization: CustomizationFieldWithOptions,
  option: string
) => {
  const { $t } = useNuxtApp()

  switch (customization.type) {
    case CustomizationFieldType.MAIN_GROUP_DIMENSION:
    case CustomizationFieldType.STANDARD_FILTER:
      return getDimensionTranslationFromTitle(option)
    case CustomizationFieldType.MONTH:
      return $t(`date.months.${option}`)
    case CustomizationFieldType.TOGGLE:
      return $t(`settings.workspace.preferences.options.${option}`)
  }
}

const formatTranslationKeys = (value: string) => {
  const { $t } = useNuxtApp()

  const values = value.split('//')
  const valuesWithoutLast = values.length > 1 ? values.slice(0, -1) : values
  return valuesWithoutLast.map(val => $t(val)).join(' / ')
}

const formatDimensionTitles = (value: string) => {
  const values = value.split('//')
  const valuesWithoutLast = values.length > 1 ? values.slice(0, -1) : values
  return valuesWithoutLast
    .map(val => getDimensionTranslationFromTitle(val))
    .join(' / ')
}

const formatAgeYears = (value: string, withHtml: boolean) => {
  const { $t } = useNuxtApp()

  const yearsMonthsDays = yearsToYearsMonthsDays(parseFloat(value.toString()))
  return `${yearsMonthsDays.years} ${
    withHtml ? '<span class="small">' : ''
  }${$t('date.text.years', {
    count: yearsMonthsDays.years
  })}${withHtml ? '</span>' : ''}`
}

const formatCurrency = (value: string, withHtml: boolean) => {
  return `${formatLargeNumber(
    Number(formatNumber(parseFloat(value), 0)),
    withHtml
  )}${
    withHtml ? '<span class="medium">€</span>' : '' // TODO: Handle correct currency
  }`
}

const formatCurrencyExact = (
  value: string,
  fractionDigits: number,
  withHtml: boolean
) => {
  return `${addSpaceInLargeNumber(
    formatNumber(parseFloat(value), fractionDigits)
  )}${withHtml ? '<span class="medium"> €</span>' : ''}` // TODO: Handle correct currency
}

const formatPercent = (
  value: string,
  fractionDigits: number,
  withHtml: boolean
) => {
  return `${parseFloat(formatNumber(parseFloat(value), fractionDigits))} ${
    withHtml ? '<span class="medium">%</span>' : '%'
  }`
}
