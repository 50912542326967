import { positiveMod } from './number'
import { formatMemberValue } from './analyticsFormat'
import { filterNullableElements } from './list'
import {
  getMultiYearComparePeriods,
  isDateRangeMatchingPastFiscalYearOrYearToDate
} from '~/services/period'
import {
  getFormattedMainTimeDimensionPeriod,
  getFormattedSecondaryTimeDimensionPeriod,
  getGranularityFromDimensions,
  getMainTimeDimensionByTimeGrain
} from '~/services/timeDimension'
import {
  getCubeFromMeasureTitle,
  getMeasureFromTitle,
  getMemberFromTitle
} from '~/services/explore'
import { ANALYTICS_GET_QUERY } from '~/gql/analytics/analyticsGetQuery'
import { ANALYTICS_GET_OPTIONS } from '~/gql/analytics/analyticsGetOptions'
import { ANALYTICS_GET_FILTER_OPERATORS_FOR_MEMBER } from '~/gql/analytics/analyticsGetFilterOperatorsForMember'
import {
  QueryTimeType,
  type DimensionValue,
  type Filter,
  type QueryTimeOption
} from '~/types/analytics'
import {
  FilterOperator,
  OrderValue,
  type Order,
  type Dimension
} from '~/types/cube'
import { DIMENSION_TITLES } from '~/constants/analytics'
import {
  TimeGrain,
  type FullPeriod,
  CustomTimeGrain
} from '~/types/timeDimension'
import type {
  PartialQuery,
  Query,
  ResultSet,
  ResultSetItemValue,
  TimeDimension,
  TimeDimensionWithComparison
} from '~/types/query'
import { AnalyticsCube, EMPTY_VALUE } from '~/constants/cube'
import {
  ALL_MAIN_TIME_DIMENSION_TITLES,
  ALL_SECONDARY_TIME_DIMENSION_TITLES,
  ALL_TIME_DIMENSION_TITLES
} from '~/constants/timeDimension'
import { useCustomizationStore } from '~/stores/customization'
import { CUSTOM_QUERY_RENDERER_ERROR_KEYS } from '~/constants/errors'
import type { NormalizedFieldEntry } from '~/types/sourceValue'
import { FormatContext } from '~/types/analyticsFormat'

export const analyticsGetOptions = async (
  dimension: string,
  filters: Filter[],
  scopeType: string
) => {
  // TODO: Remove this logic once employee who are no longer in headcount are removed from kpi table (DATA-525)
  let allFilters: Filter[]
  if (dimension.startsWith('employee')) {
    allFilters = [
      ...filters,
      {
        member: DIMENSION_TITLES.EMPLOYEE_IS_IN_HEADCOUNT,
        operator: FilterOperator.EQUALS,
        values: ['TRUE']
      }
    ]
  } else {
    allFilters = filters
  }
  const { $apiGqlClient } = useNuxtApp()
  return (
    await $apiGqlClient.query({
      query: ANALYTICS_GET_OPTIONS,
      fetchPolicy: 'cache-first',
      variables: { dimension, filters: allFilters, scopeType }
    })
  ).data.analyticsGetOptions as { [dimension: string]: string | number }[]
}

export const analyticsGetQuery = async (
  query: Query,
  scopeType: string
): Promise<ResultSet> => {
  const { $apiGqlClient } = useNuxtApp()
  return (
    await $apiGqlClient.query({
      query: ANALYTICS_GET_QUERY,
      fetchPolicy: 'cache-first',
      variables: { query, scopeType }
    })
  ).data.analyticsGetQuery
}

export const analyticsGetFilterOperatorsForMember = async (
  memberTitle: string,
  memberType: string,
  isScopeFilter: boolean
) => {
  const { $apiGqlClient } = useNuxtApp()
  return (
    await $apiGqlClient.query({
      query: ANALYTICS_GET_FILTER_OPERATORS_FOR_MEMBER,
      fetchPolicy: 'cache-first',
      variables: {
        memberTitle,
        memberType,
        isScopeFilter
      }
    })
  ).data.analyticsGetFilterOperatorsForMember as {
    name: FilterOperator
  }[]
}

export const compareValuesAlphabetically = (
  value1: ResultSetItemValue | DimensionValue,
  value2: ResultSetItemValue | DimensionValue
) => {
  if (value1 === null) {
    return 1
  }

  if (value2 === null) {
    return -1
  }
  return value1.toString().localeCompare(value2.toString(), undefined, {
    numeric: true
  })
}

const buildQueryTimeDimensions = (
  measureShortTitles: string[],
  dimensionShortTitles: string[],
  selectedPeriod: FullPeriod,
  timeOption: QueryTimeOption
): Query['timeDimensions'] => {
  const shouldUseTimeGranularity = measureShortTitles.some(
    measureShortTitle =>
      getMeasureFromTitle(measureShortTitle).meta.shouldUseTimeGranularity
  )

  const { isInFiscalMode } = useCustomizationStore()

  switch (timeOption.type) {
    case QueryTimeType.CUSTOM_PERIOD:
      return [
        {
          dimension: DIMENSION_TITLES.DATE,
          dateRange: timeOption.dateRange
        }
      ]
    case QueryTimeType.PERIOD_WITH_COMPARISON:
      return [
        {
          dimension: DIMENSION_TITLES.DATE,
          compareDateRange: [
            selectedPeriod.dateRange,
            selectedPeriod.compareDateRange
          ]
        }
      ]
    case QueryTimeType.PERIOD_WITH_TREND_GRAIN:
      return [
        {
          dimension: DIMENSION_TITLES.DATE,
          dateRange: selectedPeriod.trendDateRange,
          ...(shouldUseTimeGranularity
            ? {
                granularity: getGranularity(
                  selectedPeriod.trendSubPeriod,
                  isInFiscalMode
                )!
              }
            : {})
        }
      ]
    case QueryTimeType.PERIOD:
      if (!shouldUseTimeGranularity) {
        return [
          {
            dimension: DIMENSION_TITLES.DATE,
            dateRange: selectedPeriod.dateRange
          }
        ]
      } else {
        const granularity = getGranularityFromDimensions(
          dimensionShortTitles as DIMENSION_TITLES[]
        )
        return [
          {
            dimension: DIMENSION_TITLES.DATE,
            dateRange: selectedPeriod.dateRange,
            ...(granularity ? { granularity } : {})
          }
        ]
      }

    case QueryTimeType.MULTI_YEAR_PERIOD: {
      const granularity = shouldUseTimeGranularity
        ? getGranularityFromDimensions(
            dimensionShortTitles as DIMENSION_TITLES[]
          )
        : null

      return [
        {
          dimension: DIMENSION_TITLES.DATE,
          compareDateRange: getMultiYearComparePeriods(
            new Date(selectedPeriod.dateRange[0]!),
            new Date(selectedPeriod.dateRange[1]!),
            isInFiscalMode
          ),
          // If granularity is equal to 'year' we should not use it because we want YTD data for the last year
          ...(granularity && granularity !== TimeGrain.YEAR
            ? { granularity }
            : {})
        }
      ]
    }
  }
}

const buildQueryDimensions = (
  dimensions: string[] | undefined,
  selectedPeriod: FullPeriod,
  timeOption: QueryTimeOption
): string[] => {
  if (timeOption.type === QueryTimeType.PERIOD_WITH_TREND_GRAIN) {
    return [getMainTimeDimensionByTimeGrain(selectedPeriod.trendSubPeriod)]
  }
  return dimensions || []
}

const buildQueryOrder = (
  order: Order[] | undefined,
  measures: [string, ...string[]],
  dimensions: string[],
  timeOption: QueryTimeOption
): Order[] => {
  if (order && order.length > 0) {
    return order
  }

  if (
    timeOption.type === QueryTimeType.PERIOD_WITH_TREND_GRAIN &&
    dimensions[0]
  ) {
    return [[dimensions[0], OrderValue.ASC]]
  }

  return [[measures[0], OrderValue.DESC]]
}

export const buildCompleteQuery = (
  query: PartialQuery,
  timeOption: QueryTimeOption,
  selectedPeriod: FullPeriod,
  additionalFilters: Filter[]
): Query => {
  const dimensions = buildQueryDimensions(
    query.dimensions,
    selectedPeriod,
    timeOption
  )

  return {
    ...query,
    measures: query.measures,
    dimensions,
    timeDimensions: buildQueryTimeDimensions(
      query.measures,
      dimensions,
      selectedPeriod,
      timeOption
    ),
    order: buildQueryOrder(query.order, query.measures, dimensions, timeOption),
    filters: [...(query.filters ?? []), ...additionalFilters]
  }
}

export const getFormattedDimensionValue = (
  value: ResultSetItemValue | undefined,
  dimension: Dimension,
  withListAndKpiFormat: boolean = false
) => {
  if (value === null || value === undefined) {
    return EMPTY_VALUE
  }

  if (
    ALL_MAIN_TIME_DIMENSION_TITLES.includes(
      dimension.shortTitle as (typeof ALL_MAIN_TIME_DIMENSION_TITLES)[number]
    )
  ) {
    return getFormattedMainTimeDimensionPeriod(
      value as string,
      dimension.shortTitle as (typeof ALL_MAIN_TIME_DIMENSION_TITLES)[number]
    )
  }

  if (
    ALL_SECONDARY_TIME_DIMENSION_TITLES.includes(
      dimension.shortTitle as (typeof ALL_SECONDARY_TIME_DIMENSION_TITLES)[number]
    )
  ) {
    return getFormattedSecondaryTimeDimensionPeriod(
      Number(value),
      dimension.shortTitle as (typeof ALL_SECONDARY_TIME_DIMENSION_TITLES)[number]
    )
  }

  return formatMemberValue(
    value,
    (withListAndKpiFormat && dimension.meta.listAndKpiFormat) ||
      dimension.meta.format,
    FormatContext.DEFAULT,
    false
  )
}

export const getGranularity = (
  granularity: TimeGrain,
  isFiscalQuery: boolean
): TimeGrain | CustomTimeGrain => {
  const { fiscalYearStartMonth } = useCustomizationStore()

  if (isFiscalQuery) {
    if (granularity === TimeGrain.YEAR) {
      return CustomTimeGrain[
        `FISCAL_YEAR_${(
          Number(fiscalYearStartMonth) - 1
        ).toString()}` as keyof typeof CustomTimeGrain
      ]
    } else if (granularity === TimeGrain.QUARTER) {
      const quarterOffset = positiveMod(fiscalYearStartMonth - 1, 3)

      if (quarterOffset === 0) {
        return TimeGrain.QUARTER
      } else {
        return CustomTimeGrain[`FISCAL_QUARTER_${quarterOffset}`]
      }
    }
  }

  return granularity
}

export const checkQuery = (query: Query) => {
  const measures = query.measures.map(measure => getMeasureFromTitle(measure))

  if (
    measures.some(
      measure => measure.meta.canNotBeRequestedWithTimeDimensions
    ) &&
    query.dimensions.some(dimension =>
      ALL_TIME_DIMENSION_TITLES.includes(
        dimension as (typeof ALL_TIME_DIMENSION_TITLES)[number]
      )
    )
  ) {
    throw new Error(
      CUSTOM_QUERY_RENDERER_ERROR_KEYS.ANALYTICS_INAPPLICABLE_TEMPORAL_DIMENSION
    )
  }

  const usedCubes = query.measures.map(measure =>
    getCubeFromMeasureTitle(measure)
  )

  if (usedCubes.includes(AnalyticsCube.EMPLOYEE_YEARLY_SNAPSHOTS)) {
    const dateRanges = [
      ...filterNullableElements(
        query.timeDimensions.map(
          timeDimension =>
            (timeDimension as TimeDimension).dateRange as
              | [string, string]
              | undefined
        )
      ),
      ...filterNullableElements(
        query.timeDimensions.map(
          timeDimension =>
            (timeDimension as TimeDimensionWithComparison).compareDateRange as
              | [string, string][]
              | undefined
        )
      ).flat()
    ]

    if (
      dateRanges.some(
        dateRange => !isDateRangeMatchingPastFiscalYearOrYearToDate(dateRange)
      )
    ) {
      throw new Error(
        CUSTOM_QUERY_RENDERER_ERROR_KEYS.ANALYTICS_YEARLY_MEASURE_INCOMPATIBLE_DATE_RANGE
      )
    }

    const timeDimension = query.dimensions.find(dimension =>
      ALL_TIME_DIMENSION_TITLES.includes(
        dimension as (typeof ALL_TIME_DIMENSION_TITLES)[number]
      )
    )

    if (timeDimension) {
      const { isInFiscalMode } = useCustomizationStore()

      const isYearDimensionSelected = isInFiscalMode
        ? timeDimension === DIMENSION_TITLES.DATE_FISCAL_YEAR
        : timeDimension === DIMENSION_TITLES.DATE_YEAR

      if (!isYearDimensionSelected) {
        throw new Error(
          CUSTOM_QUERY_RENDERER_ERROR_KEYS.ANALYTICS_YEARLY_MEASURE_INCOMPATIBLE_TEMPORAL_DIMENSION
        )
      }
    }
  }
}

export const getMembersFromQuery = (query: PartialQuery) => {
  return [
    ...query.measures,
    ...(query.dimensions || []),
    ...(query.filters?.map(f => f.member) || []),
    ...(query.order?.map(o => o[0]) || [])
  ]
}

export const getAssociatedNormalizationFields = (
  memberShortTitles: string[]
): NormalizedFieldEntry[] => {
  return [
    ...new Set(
      memberShortTitles
        .map(memberShortTitle => {
          const member = getMemberFromTitle(memberShortTitle)

          return member?.meta?.normalizationFieldEntries || []
        })
        .flat()
    )
  ]
}
